.current {
  color: green;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media (prefers-color-scheme: dark) {
  body {
    background: black;
    color: white;
  }

  a {
    color: yellow;
  }

  a:visited {
    color: gray;
  }
}

.imgbox {
  display: grid;
  height: 100%;
}

.center-fit {
  max-width: 100%;
  max-height: 100vh;
  margin: auto;
}
